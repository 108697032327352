/*#root {
  position: relative;
  z-index: 1;
}
#leva__root {
  position: absolute;
  z-index: 9;
}*/



.App {
  height:100vh;
  /*background:#eaeaea;
  background:linear-gradient(to bottom right, #F6FEFF, #E0FDFF);*/
  --colorJauneMG : #ffe421;
  --colorBlackMG : #182512;
  --colorWhiteMG : #fff;
  font-family: "Inter", sans-serif;
}
p {
  color:var(--colorWhiteMG);
  font-size:14px;
}
#loaderMarcheGare {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  display:flex;
  flex-direction: column;
  justify-content: center;
}
#loaderMarcheGare label {
  text-align: center;
  margin-bottom:5px;
  color:var(--colorBlackMG);
}
.panneauLeft {
  position:absolute;
  background:var(--colorJauneMG);;
  transition:.5s;
  width:350px;
  height:100vh;
  padding:20px;
}
.panneauLeft p {
  color:var(--colorBlackMG);;
}
.panneauLeft img {
  width:100%;
}
.boutonFermeturePanneau {
  text-align:right;
}
.boutonFermeturePanneau:hover {
  cursor:pointer;
}
.boutonFermeturePanneau img {
  width:20px;
  margin-bottom:10px;
}
.pointsOfInterest {
  width:20px;
  height:20px;
  border-radius:50%;
  background:var(--colorJauneMG);;
  opacity:0.8;
}
.pointsOfInterest:hover {
  cursor:pointer;
}
/*#frame {
  width:100%;
  height:100%;
}
#frame div {
  position:absolute;
  width:40px;
  height:20px;
  background:#eaeaea;
}
#frame div:first-of-type {
  width:100%;
}
#frame div:nth-of-type(2) {
  bottom:0;
  width:100%;
}
#frame div:nth-of-type(3) {
  height:100%;
}
#frame div:last-of-type {
  right:0;
  height:100%;
}*/
#elementsAround {
  position:absolute;
  top:0;
  width:100%;
  height:100%;
}
#elementsAround h1,
#elementsAround p {
  color:var(--colorBlackMG);
}
#elementsAround > * {
  position:absolute;
}
#titre {
  top:50px;
  left:100px;
}
#titre h1 {
  margin:0;
}
#titre p:first-of-type {
  position:relative;
  width:-moz-fit-content;
  width:fit-content;
  margin:1px 0;
  font-weight:700;
}
#titre p:first-of-type:before {
  content:"";
  display:inline-block;
  position:absolute;
  z-index:-1;
  width:120%;
  height:22px;
  background:var(--colorJauneMG);
  transform: translate(-9%,-10%) rotate(-3deg);
}
#maquetteInteractive {
  top:50%;
  left:100px;
  transform:translateY(-50%);
  font-weight:700;
}
#maquetteInteractive > div {
  position:relative;
  background:green;
}
#maquetteInteractive > div > div {
  position:absolute;
  top:13px;
  left:-23px;
  transform:translate(-50%,-50%);
  border-radius: 50%;
}
#maquetteInteractive > div > div:first-of-type {
  width:16px;
  height:16px;
  background:var(--colorBlackMG);
}
#maquetteInteractive > div > div:last-of-type {
  width:24px;
  height:24px;
  border:1px solid var(--colorBlackMG);
}
#maquetteInteractive p {
  margin:0;
  font-weight:400;
}
#maquetteInteractive p:first-of-type {
  font-weight:700;
}
#trait {
  width:1px;
  height:50px;
  left: 100px;
  bottom:50px;
  background:var(--colorBlackMG);
}
#timeline {
  bottom:50px;
  left:50%;
  transform:translateX(-50%);
  display:flex;
  gap: 0 20px;
}
#timeline div {
  background:var(--colorBlackMG);
}
#timeline div:hover,
#timeline div.active {
  background:var(--colorJauneMG);
}
#timeline div:hover {
  cursor:pointer;
}
#timeline div.active {
  cursor:default;
}
#timeline p {
  color:var(--colorWhiteMG);
  margin:3px 13px 5px 13px;
  font-weight:700;
}
#timeline div:hover p,
#timeline div.active p {
  color:var(--colorBlackMG);
}
#logos {
  top:50px;
  right:100px;
}
#logos img {
  height:50px;
}
#listeBatiments {
  top:50%;
  right:100px;
  transform:translateY(-50%);
}
#listeBatiments p {
  position:relative;
}
#listeBatiments p span {
  position:relative;
  padding:0 5px;
}
#listeBatiments p span:before {
  content:'';
  position:absolute;
  left:0;
  z-index:-1;
  width:0%;
  height:100%;
  background:var(--colorJauneMG);
  transition:0.2s;
}
#listeBatiments p.active span:before {
  width:100%;
}
#listeBatiments p:hover {
  cursor:pointer;
}
#listeBatiments p:before {
  content:'';
  position:absolute;
  top:10px;
  left:-30px;
  width:20px;
  height:1px;
  background:var(--colorBlackMG);
}
#retourAgate {
  bottom:50px;
  right:100px;
  text-decoration: none;
}
#retourAgate p {
  margin:0;
}